@font-face {
  font-family: 'Capitals';
  src: url('./capitals.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

a:link {color: black;}
a:visited {color: black;}
a:hover {color: black !important;}
a:active {color: black;}

.categoryLabel:hover {
  cursor: pointer;
  background: gray !important;
}

.uploadDisabled {
  display: none !important;
}

.categoryLabel {
  margin-right: 5px !important;
}

.title {
  margin: 100px 0px 100px 0px;
}

.caption {
  font-size: 45px;
  font-family: 'Capitals';
  color: rgba(0, 0, 0, 0.8);
}

.subcaption {
  font-size: 45px;
  font-family: 'Times';
  font-style: italic;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 2.2px;
}

.imageContainer {
  height: 218px;
  width: 323px;
  margin: 5px;
}

.imageContainerPanorama {
  height: 109px;
  width: 323px;
  margin: 5px;
}

.imageTitle {
  float: left;
}

.uploadPane {
  min-height: 50px;
  margin-top: 40px;
}

.imageLink {
  max-height: 240px;
  max-width: 310px;
  min-height: 240px;
  min-width: 310px;
  overflow: hidden;
}

.progressBar {
  margin: auto !important;
  position: relative !important;
  top: 0; left: 0; bottom: 0; right: 0 !important;
}

.titleInput {
  margin-top: 5px;
  min-width: 310px;
}

.editPane {
  margin-top: 90px;
  min-width: 310px;
  margin-left: 5px;
}

.adminButtons {
  position: absolute;
  top: 0px;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
}

.selectedLabel {
  background: black !important;
  color: white !important;
}

.unselectedLabel {
  background: #e8e8e8 !important;
  color: rgba(0,0,0,.6) !important;
}

.imageHover {
  text-align: center;
  vertical-align: middle;
  line-height: 218px;
  color: #000;
  font-size: 18px;
  font-family: 'Cambo', sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
}

.imageHoverPanorama {
  text-align: center;
  vertical-align: middle;
  line-height: 109px;
  color: #000;
  font-size: 18px;
  font-family: 'Cambo', sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
}

.ui.fade.reveal:hover>.visible.content {
  opacity: 0.6 !important;
}

.notSelected {
  display: none !important;
}

.signIn {
  position:absolute;
  top:0;
}

.thumbs {
  display: inline-block;
}

.thumbsContainer {
  text-align: center;
}

.imageText {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 3.5em;
  font-family: 'Capitals';
  letter-spacing: 2px;
  width: 100%;
  height: 50%;
  text-align: center;
  color: white;
}

.imageThumb {
  position: relative;
  margin: 5px;
  float: left;
}

.imageThumb:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.header {
  flex: 0 1 auto;
  text-align: center;
}

.content {
  flex: 1 1 auto;
}

.footer {
  flex: 0 1 auto;
}

#root {
  height: 100vh;
}

.container {
  display: flex;
  flex-flow: column;
  height: 100vh;
  max-width: 1000px;
  margin: 0 auto;
}
